<template>
  <span v-if="!loading">

    <a :href="'/agent/'+item.agent.agent_id+'/main'" style="float: left" v-if="item.agent">
      <v-icon>mdi-arrow-up-left</v-icon> Agentti {{ item.agent.name }}
    </a>

    <h1 class="headline white--text text-center mb-3" v-if="item.id">Audio alias: {{ item.alias_name }}</h1>
    <h1 class="headline white--text" v-else>Luo uusi Audio-alias</h1>

    <!-- navigation tabs -->
    <v-tabs
        hide-slider
        show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
          v-for="tab of pageTabs"
          :key="tab.id"
          :to="'/audio_alias/'+item.id+'/'+tab.id"
          :disabled="tabDisabler(tab)"
      >
        {{tab.name}}
        <span v-if="item[tab.count]">({{ item[tab.count] }})</span>
      </v-tab>
    </v-tabs>

    <!-- Main form -->
    <div v-if="page == 'main' || page == 'new'" class="sub-page">

      <h1 class="headline blue--text text-center mt-4 mb-3">Perustiedot</h1>
      <v-divider></v-divider>

      <!-- form segment -->
      <v-form
          class="form-element"
          ref="form"
      >
        <!-- formFields loop -->
        <span v-for="data in formFields" :key='data.item_id'>

          <!-- show chips when needed -->
          <v-chip-group
              v-if="data.chips"
              column
          >
            <!-- single chip -->
            <v-chip
                color="blue"
                label
                class="darken-4"
                v-if="!data.multiple && item[data.item_id] && Object.keys(item[data.item_id]).length > 0"
                :close="data.chips_close"
                @click:close="chipsClose(data, item[data.item_id])"
                :large="data.chips_large"
            >
              <router-link :to="'/agent/'+item[data.item_id].agent_id+'/main'">{{ data.chips_name ? item[data.item_id][data.chips_name] : item[data.item_id].id }}</router-link>
            </v-chip>
            <!-- multiple chips -->
            <v-chip v-else
                    color="blue"
                    label
                    class="darken-4"
                    v-for="chip in item[data.item_id]"
                    :key="'chip'+chip.id"
                    :close="data.chips_close"
                    @click:close="chipsClose(data, chip)"
            >
              {{ data.chips_name ? chip[data.chips_name] : chip.name }}
            </v-chip>
          </v-chip-group>

          <!-- anonymous component to handle form stuff -->
          <component
              :is="data.component"
              v-model="item[data.item_id]"
              :items="searchItems[data.item_id]"
              :label="data.label"
              :hint="data.hint"
              :persistent-hint="data.persistent_hint"
              :search-input.sync="searchInputs[data.item_id]"
              :loading="searchLoading[data.item_id]"
              :item-text="data.item_text"
              :item-value="data.item_value"
              :no-data-text="data.no_data_text"
              :multiple="data.multiple"
              :chips="data.chips"
              :return-object="data.return_object"
              :background-color="data.background_color"
              :rules="data.rules"
              hide-no-data
              @focus="componentFocus(data)"
          >

            <!-- disable selection, using external selection system -->
            <template v-slot:selection>
              <span v-if="data.item_id == 'offer_level'">{{ item[data.item_id] }}</span>
            </template>

            <template v-slot:item="data">
              <template v-if="data.item.name">{{ data.item.name }}</template>
              <template v-if="data.item.info">{{ data.item.info }}</template>
            </template>
            <template v-if="data.v_html">
              {{ data.v_html }}
            </template>
            <template v-if="data.radios">
                <v-radio v-for="r in data.radios"
                         :key="r.key"
                         :value="r.value"
                         :label="r.label"
                ></v-radio>
            </template>
          </component>

        </span>

        <!--  submit buttons -->
        <v-row class="mt-12 mb-16">
          <v-col>
            <v-btn class="button green" @click="submit">
              <template v-if="item.id">Päivitä tiedot</template>
              <template v-else>Lisää uusi audio alias</template>
            </v-btn>
          </v-col>
          <v-col
              v-if="item.id"
              aligh="right">
            <v-btn
                class="button red"
                @click="deleteAlias"
            >
              Poista tämä alias
            </v-btn>
          </v-col>
        </v-row>


      </v-form>

    </div>

    <!-- ads -->
    <div v-if="page == 'ads'" class="sub-page">

      <h1 class="headline blue--text text-center mt-4 mb-3">
        Esittelyt (ads)</h1>
      <v-divider></v-divider>

      <alias-advertise
          :id="item.id"
      >
      </alias-advertise>

    </div>

    <!-- audio_presentations -->
    <div v-if="page == 'audio_presentations'" class="sub-page">

      <h1 class="headline blue--text text-center mt-4 mb-3">Ääniesittelyt</h1>
      <v-divider></v-divider>

      <!-- main presentation -->
      <v-card class="mt-5">
        <v-card-title>Pääesittely puhelinpalvelussa (main presentation)</v-card-title>
        <v-card-text>

          <audio
              v-if="mainPresentationPlayback"
              controls="controls" controlslist="nodownload" :src="mainPresentationPlayback">
              Your browser does not support the
              <code>audio</code> element.
          </audio>

        <v-file-input
            v-model="mainPresentation"
            accept="audio/wav, audio/mp3"
            label="Syötä äänitiedosto"
        ></v-file-input>
        </v-card-text>
      </v-card>

      <!-- secondary presentation data:audio/wav;base64,-->
      <v-card class="mt-5">
        <v-card-title>Toissijainen esittely puhelinpalvelussa (secondary presentation)</v-card-title>
        <v-card-text>

          <audio
              v-if="secondaryPresentationPlayback"
              controls="controls" controlslist="nodownload" :src="secondaryPresentationPlayback">
              Your browser does not support the
              <code>audio</code> element.
          </audio>

        <v-file-input
            v-model="secondaryPresentation"
            accept="audio/wav, audio/mp3"
            label="Syötä äänitiedosto"
        ></v-file-input>
        </v-card-text>
      </v-card>

      <!-- web presentation -->
      <v-card class="mt-5">
        <v-card-title>Nettiesittely (web presentation)</v-card-title>
        <v-card-text>
          <audio v-if="this.item.web_presentation" controls="controls" controlslist="nodownload" :src="'https://d2qy3ntxfklvyh.cloudfront.net/'+this.item.web_presentation.filename">
              Your browser does not support the
              <code>audio</code> element.
          </audio>

          <v-file-input
              v-model="webPresentation"
              accept="audio/wav, audio/mp3"
              label="Syötä äänitiedosto"
          ></v-file-input>
        </v-card-text>
      </v-card>


    </div>

    <div v-if="page == 'media'" class="sub-page">

      <h1 class="headline blue--text">Kuvat ja videot</h1>

      <alias-media v-if="item.alias_media" :id="item.id" :items="item.alias_media"></alias-media>

    </div>

      <!-- audio reporting -->
      <span v-if="page == 'audio_report'">
          <h1 class="headline blue--text text-center mt-4 mb-3">Puhelujen statistiikkaa</h1>
          <v-divider></v-divider>
          Valitse päivämäärä:
          <v-row>
              <v-col cols="2">
                <v-text-field v-model="report_start_date" placeholder="01.12.23" label="alku"/>
              </v-col>
              <v-col cols="2">
                  <v-text-field v-model="report_end_date" placeholder="31.12.23" label="loppu"></v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-btn
                          class="v-btn ma-3 green float-right"
                          @click="getAudioReporting"
                          small
                  >
                      HAE
                  </v-btn>
              </v-col>
          </v-row>

          <v-card>
              <v-card-text>

                  <v-simple-table
                          v-if="audio_report.length > 0"
                  >

                  <template v-slot:default>
                      <!--
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          val
                        </th>
                      </tr>
                    </thead>
                    -->
                    <tbody>
                      <tr
                              v-for="item in audioReport"
                              :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>

                  </v-simple-table>
              </v-card-text>
          </v-card>
      </span>

    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        color="green"
    >
      {{ snackbar_message }}
    </v-snackbar>


  </span>
  <v-skeleton-loader
      v-else
      class="mx-auto"
      type="card, article, actions"
  >
  </v-skeleton-loader>
</template>

<script>
import GlobalVars from "../mixins/GlobalVars";

//manually import vuetify form objects for dynamic usage
import {
  VTextField,
  VTextarea,
  VChipGroup,
  VImg,
  VAutocomplete,
  VCheckbox,
  VSubheader,
  VDivider,
  VContainer,
  VSnackbar,
  VRadioGroup,
  VRadio,
  VSelect,

} from 'vuetify/lib'
import AliasMedia from "../components/AliasMedia.vue";
import AliasAdvertise from "../components/AliasAdvertise";
import moment from "moment/moment";

export default {
  name: "AudioAlias",
  mixins: [
    GlobalVars,
  ], //import default set of values
  props: [
    'id',
    'page',
    'agent_id'
  ],
  components: {
    VTextField,
    VChipGroup,
    VImg,
    VCheckbox,
    VTextarea,
    VAutocomplete,
    VSubheader,
    VDivider,
    VContainer,
    VSnackbar,
    VRadioGroup,
    VRadio,
    AliasMedia,
    AliasAdvertise,
    VSelect

  },
  data: function () {
    return {
      item: {
        agent: {}
      },
      route: 'audio_aliases/', //change to read from env variable
      pageTabs: [
        {id: 'main', name: 'perustiedot'},
        {
          id: 'ads',
          name: 'Esittelyt',
          count: 'ads_count',
        },
        {
          id: 'audio_presentations',
          name: 'Ääniesittelyt',
        },
        { id: 'audio_report', name: 'Audiorapsa'},
      ],
      formFields: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'alias_name',
          label: 'Nimi',
          hint: null,
          component: 'v-text-field',
          rules: [(v) => !!v || "Nimi ei voi olla tyhjä"],
        },
          /*
        {
          item_id: 'public_id',
          label: 'Public ID',
          hint: 'Aliaksen tunniste URL-osoitteessa. Ei voi olla kahta saman tyypin aliasta, jolla on sama public_id. Muuta tätä vain jos on ehdottomasti pakko',
          component: 'v-text-field',
          rules: [(v) => !!v || "Public ID ei voi olla tyhjä"],
        },
        */
        {
          item_id: 'introduction',
          label: 'Kuvaus',
          hint: null,
          component: 'v-text-field',
          counter: 65,
          rules: [(v) => !!v && v.length <= 65 || '1-65 kirjainta'],
        },
        {
          item_id: 'agent',
          label: 'Kuuluu agentille (hae nimellä)',
          hint: 'Kuka agentti käyttää tätä aliasta',
          persistent_hint: false,
          component: 'v-autocomplete',
          multiple: false,
          chips: true,
          chips_name: 'name',
          return_object: true,
          item_text: "name",
          item_value: "id",
        },
        {
          item_id: 'active',
          label: 'Aktiivinen alias',
          hint: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'offer_level',
          label: 'Offer level',
          hint: null,
          component: 'v-select',
          return_object: false,
          item_text: "name",
          item_value: "id",
        },
        {
          item_id: 'attributes',
          label: 'Attribuutit',
          hint: 'Määrittää aliaksen ominaisuudet audio palveluissa',
          persistent_hint: true,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          return_object: true,
          item_text: "info",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          chips_name: 'info',
          chips_close: true,
          chips_close_action: true,
          chips_close_message: 'attribuutti poistettiin',
          on_focus: 'attributes',
        },

        {
          component: 'v-divider',
        },

        {
          item_id: 'categories',
          label: 'Kategorioissa',
          hint: 'Alias kuuluu näihin kategorioihin',
          persistent_hint: true,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          chips_close: true,
          chips_close_action: true,
          chips_close_message: 'kategoria poistettiin',
          on_focus: 'categories',
        },
        {
          item_id: 'sub_categories',
          label: 'Alikategorioissa',
          hint: 'Alias kuuluu näihin alikategorioihin',
          persistent_hint: true,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          chips_close: true,
          chips_close_action: true,
          chips_close_message: 'alikategoria poistettiin',
          on_focus: 'sub_categories',
        },
        {
          component: 'v-divider',
        },

      ],
      searchItems: {
        attributes: null,
        categories: null,
        sub_categories: null,
        agent: null,
        offer_level: [
          {id: 1, name: '1'},
          {id: 2, name: '2'},
          {id: 3, name: '3'},
          {id: 4, name: '4'},
          {id: 5, name: '5'},
          {id: 6, name: '6'},
          {id: 7, name: '7'},
          {id: 8, name: '8'},
          {id: 9, name: '9'},
        ],
        aoffer_level: [1, 2, 3, 4],
      },
      searchInputs: {
        attributes: "",
        categories: "",
        sub_categories: "",
        agent: "",
      },
      searchLoading: {
        attributes: false,
        categories: false,
        sub_categories: false,
        agent: false,
      },
      mainPresentation: null,
      mainPresentationPlayback: null,
      secondaryPresentation: null,
      secondaryPresentationPlayback: null,
      webPresentation: null,
        audio_report: [],
        report_start_date: null,
        report_end_date: null,
        moment: moment,
    }
  },
    computed: {

        audioReport(){

            if(this.audio_report)
                return [
                    {'name': 'puheluita yhteensä', value: this.audio_report[0].number_of_calls},
                    {'name': 'onnistuneet puhelut', value: this.audio_report[0].successful_calls},
                    {'name': 'Suorat puhelut agentille', value: this.audio_report[0].number_of_calls_to_agent},
                    {'name': 'Epäsuorat puhelut', value: this.audio_report[0].number_of_calls_not_to_agent},
                    {'name': 'Agentin sulkema puhelu', value: this.audio_report[0].agent_hangup_ok_call},
                    {'name': 'Asiakkaan sulkema puhelu', value: this.audio_report[0].customer_hangup_ok_call},
                    {'name': 'Asiakkaan sulkema puhelu epäonnistunut', value: this.audio_report[0].customer_hangup_nok_call},
                    {'name': 'Agentin vastaamattomat', value: this.audio_report[0].not_answered_by_agent+' ('+this.audio_report[0].missed_call_percentage+'%)', 'color': 'red'},
                    {'name': 'Agentin poistui puhelusta', value: this.audio_report[0].agent_leave_ok_call},
                    {'name': 'Asiakas poistui puhelusta', value: this.audio_report[0].customer_leave_ok_call},
                    {'name': 'Asiakas poistui puhelusta epäonnistunut', value: this.audio_report[0].customer_leave_nok_call},

                    {'name': 'Agentti online', value: this.audio_report[0].agent_online_minutes},
                    {'name': 'Agentin puhelut', value: this.audio_report[0].agent_spoken_total},
                    {'name': 'Agentin puhelut keskiarvo', value: this.audio_report[0].agent_spoken_minutes_average},
                    {'name': 'Online / puhelu suhde', value: this.audio_report[0].online_to_call_ratio},


                ]
            else
                return {}

        },

    },
  watch: {

    agent_id(){

      if(this.agent_id){
        console.log('agent_id'+this.agent_id)

        this.axios.get('/agents/'+this.agent_id).then((response) => {
          this.item.agent = response.data
        })
      }



    },

    page(){

        if(this.page == 'audio_report') this.getAudioReporting()

      if(this.page == 'new'){

        this.item = {}
        if(this.$refs.form) this.$refs.form.reset()
      }

      if(this.page == 'audio_presentations'){

        if(this.item.alias_presentation_id) {

          this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/main/base64')
              .then((response) => {
                this.mainPresentationPlayback = 'data:audio/wav;base64,'+response.data
              })

          this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/secondary/base64')
              .then((response) => {
                this.secondaryPresentationPlayback = 'data:audio/wav;base64,'+response.data
              })

        }

        this.axios.get('audio_aliases/' + this.id + '/web_presentation/')
            .then((response) => {
              this.item.web_presentation = response.data
            })



      }

    },

    id(){

      if(this.$refs.form) this.$refs.form.reset()

      if(this.id) this.apiGet(this.route+this.id)

    },

    mainPresentation(){

      if(!this.mainPresentation) return //silent fail

      this.mainPresentationPlayback = null

      var formData = new FormData();
      formData.append('file', this.mainPresentation)

      this.axios.post('/audio_aliases/'+this.item.id+'/audio_presentation/'+this.item.alias_presentation.id+'/main', formData)
          .then((response)=>{
            console.log(response)
            this.snackbar = true
            this.snackbar_message = 'Pääesittely puhelinpalvelussa (main presentation) lisätty/päivitetty'

            this.item.alias_presentation = response.data
            //this.mainPresentationPlayback = 'data:audio/wav;base64,'+this.mainPresentation
            this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/main/base64')
                .then((response) => {
                  this.mainPresentationPlayback = 'data:audio/wav;base64,'+response.data
                })
          })
    },

    secondaryPresentation(){

      if(!this.secondaryPresentation) return //silent fail

      this.secondaryPresentationPlayback = null

      var formData = new FormData();
      formData.append('file', this.secondaryPresentation)

      this.axios.post('/audio_aliases/'+this.item.id+'/audio_presentation/'+this.item.alias_presentation.id+'/secondary', formData)
          .then((response)=>{
            console.log(response)
            this.snackbar = true
            this.snackbar_message = 'Toissijainen esittely puhelinpalvelussa (secondary presentation) lisätty/päivitetty'

            this.item.alias_presentation = response.data
            //this.secondaryPresentationPlayback = 'data:audio/wav;base64,'+this.secondaryPresentation
            this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/secondary/base64')
                .then((response) => {
                  this.secondaryPresentationPlayback = 'data:audio/wav;base64,'+response.data
                })
          })
    },

    webPresentation(){

      if(!this.webPresentation) return //silent fail

      var formData = new FormData();
      formData.append('file', this.webPresentation)

      this.axios.post('/audio_aliases/'+this.item.id+'/web_presentation', formData)
          .then((response)=>{
            console.log(response)
            this.snackbar = true
            this.snackbar_message = 'Nettiesittely (web presentation)'

            this.item.web_presentation = response.data
          })
    },

    "searchInputs.attributes"(){

      if(this.searchInputs.attributes) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('attributes', 'attributes', this.searchInputs.attributes)
        }, 500)
      }

    },

    "searchInputs.categories"(){

      if(this.searchInputs.categories) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('categories', 'categories', this.searchInputs.categories)
        }, 500)
      }

    },

    "searchInputs.sub_categories"(){

      if(this.searchInputs.sub_categories) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('sub_categories', 'sub_categories', this.searchInputs.sub_categories)
        }, 500)
      }

    },

    "searchInputs.agent"(){

      if(this.searchInputs.agent && this.searchInputs.agent != "") {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('agent', 'agents', this.searchInputs.agent, '&active=1')
        }, 500)
      }

    },

  },
  methods: {

      getAudioReporting(){
          this.axios.get('/reports/audio_agent_report/', {params:
                  {
                      from: this.report_start_date,
                      to: this.report_end_date,
                      alias_id: this.id,
                  }
          }).then((response)=>{
              this.audio_report = response.data
          })
      },

    componentFocus(data){

      console.log('componentFocus', data)

      if(data.on_focus == 'categories') this.getSearchItems('categories', 'categories', this.searchInputs.categories)
      if(data.on_focus == 'sub_categories') this.getSearchItems('sub_categories', 'sub_categories', '')
      if(data.on_focus == 'attributes') this.getSearchItems('attributes', 'attributes', '')
    },

    tabDisabler(tab){

      if((tab.id == 'ads' || tab.id == 'audio_presentations') && !this.item.id) return true

      return false
    },

    submit() {

      if (!this.$refs.form.validate()) {
        this.error = this.$t('errors in form')
        return //validate form
      }

      if(this.loading) return
      this.loading = true

      var method = 'post' //to create new alias
      var route = 'audio_aliases/'

      if(this.item.id){
        route += this.item.id //to update alias
        method = 'put' //to update alias
      }

      this.axios[method](route,this.item).then((response)=>{
        this.loading = false

        this.snackbar_message = "Uusi audio alias luotu / päivitetty"
        this.snackbar = true

        //if post, make return id and store item id to this.item
        if(response.data) this.item = response.data
        if(!this.item.categories) this.item.categories = []
        if(!this.item.sub_categories) this.item.sub_categories = []
        if(!this.item.alias_media) this.item.alias_media = []
        if(!this.item.alias_media) this.item.attributes = []

        //emit item for an update for other instances
        this.$root.$emit('aliases_table_audio_aliases', this.item)

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },

    deleteAlias(){

      this.axios.delete(this.route+this.item.id).then(()=>{

        this.$root.$emit('audio_aliases_delete', this.item.id)

        this.item = {}
        this.snackbar_message = "audio alias poistettu"
        this.snackbar = true

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    }

  },
  mounted(){

      this.report_start_date = moment().startOf('month').format('DD.MM.YY');
      this.report_end_date = moment().format('DD.MM.YY');

    if(this.id) this.apiGet(this.route+this.id)
      if(this.page == 'audio_report') this.getAudioReporting()

    if(this.agent_id){
      console.log('agent_id'+this.agent_id)

      this.axios.get('/agents/'+this.agent_id).then((response) => {
        this.item.agent = response.data
      })
    }

    setTimeout(() => {

      if(this.page == 'audio_presentations'){

        if(this.item.alias_presentation_id) {

          this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/main/base64')
              .then((response) => {
                this.mainPresentationPlayback = 'data:audio/wav;base64,'+response.data
              })

          this.axios.get('audio_aliases/' + this.id + '/audio_presentation/' + this.item.alias_presentation_id + '/secondary/base64')
              .then((response) => {
                this.secondaryPresentationPlayback = 'data:audio/wav;base64,'+response.data
              })

        }

      }

      this.axios.get('audio_aliases/' + this.id + '/web_presentation/')
          .then((response) => {
            this.item.web_presentation = response.data
          })

    }, 1000)

    //only update agent activity
    this.$root.$on('audio_alias', function(payload){

      //if item is payload id, only set active to whatever it is defined
      if(this.item.id == payload.id) this.item.active = payload.active

    }.bind(this))

  }
}
</script>

<style scoped>

</style>