<template>
  <span>
    <v-row>
      <v-col>
        <v-btn
            class="v-btn ma-3 green float-right"
            @click="edit_item = {}; dialog=true"
            small
        >
          <v-icon>mdi-plus-circle-outline</v-icon> Lisää kuva tai video
        </v-btn>
      </v-col>
    </v-row>

    <v-card v-for="group in groups" :key="group.net_product_type_id">

      <template v-if="items.filter(f => f.net_product_type_id == group.net_product_type_id).length > 0">
        <v-card-title>{{ group.name }}</v-card-title>

        <v-data-table

            :items="items.filter(f => f.net_product_type_id == group.net_product_type_id)"
            :headers="headers"
            :hide-default-footer="true"
            class="mb-4"
            :items-per-page=-1
        >

          <template slot="item.filename" slot-scope="data">
            <v-img v-if="data.item.net_product_type_id == 18" :src="chat_alias_profile_picture_base+data.item.filename" max-width="200" @click="popup=true;popup_file=chat_alias_profile_picture_base+data.item.filename"></v-img>
            <v-img v-if="data.item.net_product_type_id == 14" :src="chat_alias_premium_picture_base+data.item.filename+'?name='+data.item.id" max-width="200" @click="popup=true;popup_file=chat_alias_premium_picture_base+data.item.filename"></v-img>
            <template v-if="data.item.net_product_type_id == 13">
              <video controls="controls" preload="auto" width="460" height="306" :poster="data.item.thumbnail ? 'https://d2t3tbcav0uoyo.cloudfront.net/'+data.item.thumbnail.filename : ''">
                  <source :src="'https://dgg8ygvhnykmd.cloudfront.net/'+data.item.filename" type="video/mp4">
              </video>
            </template>

          </template>

          <template slot="item.net_product_type_id" slot-scope="data">
            <template v-if="data.item.net_product_type_id == 18">Profiilikuva</template>
            <template v-if="data.item.net_product_type_id == 14">Maksullinen kuva</template>
            <template v-if="data.item.net_product_type_id == 13">Maksullinen video</template>
          </template>

          <template slot="item.price" slot-scope="data">
            <template v-if="data.item.price > 0">{{ data.item.price / 100 }}€</template>
            <template v-else>Ilmainen</template>
          </template>

          <template slot="item.edit" slot-scope="data">
            <v-btn
                v-if="data.item.net_product_type_id != 18"
                class="v-btn green" @click="edit_item = data.item; dialog=true;data.item.net_product_type_id == 13 ? selectedFileType = 'video' : selectedFileType = 'image'">muokkaa</v-btn>
            <v-btn class="v-btn red ml-2" @click="edit_item = data.item; confirmation=true;">poista</v-btn>
          </template>

        </v-data-table>
      </template>

    </v-card>

    <v-alert
        v-if="items.length == 0"
        class="red darken-4 mt-5"
    >
        Aliaksella ei ole kuvia tai videoita
    </v-alert>

    <!-- picture add/edit dialoque -->
    <v-dialog
        v-model="dialog"
        width="600"
        @click:outside="edit_item = {};selectedFile = null; selectedFile2 = null"
    >
      <v-card>
        <v-card-title v-if="edit_item.id">
          Muokkaa
        </v-card-title>
        <v-card-title v-else>
          Uusi kuva tai video
        </v-card-title>
        <v-card-text>
          <v-form>

            <!-- show file input -->
            <template v-if="!selectedFile && !edit_item.id">

              <!-- vuetify fileinput -->
              <v-file-input
                  v-model="selectedFile"
                  accept="image/*, video/mp4, video/webm"
                  label="Kuva tai video (jpg,webp,mp4,webm)"
                  truncate-length="50"
                  @click:clear="edit_item.file = null"
                  @change="fileType"
              ></v-file-input>

            </template>

            <!-- image & video preview and load progress -->
            <div v-if="media_upload" class="loading">

              <v-progress-linear
                  v-if="axiosProgress > 0"
                  v-model="axiosProgress"
                  height="25"
              >
                <strong>{{ axiosProgress }}%</strong>
              </v-progress-linear>
              <v-btn class="v-btn red" @click="abortLoading">Keskeytä</v-btn>
            </div>

            <img v-if="!edit_item.id && selectedFileType == 'image'" :src="previewSrc" style="max-width: 100%" :class="{'loading_media': media_upload}">
            <template v-if="!edit_item.id && selectedFileType == 'video'" >
              <video controls="controls" preload="auto" width="550" height="316">
                  <source :src="previewSrc" type="video/mp4, video/webm">
              </video>
            </template>

            <!-- show other stuff in form if filename or id (edit) is set -->
            <template v-if="edit_item.filename || edit_item.id">

              <template v-if="edit_item.id">
                <!-- @click="popup=true;popup_file=chat_alias_profile_picture_base+data.item.filename" -->
                <v-img v-if="edit_item.net_product_type_id == 18" :src="chat_alias_profile_picture_base+edit_item.filename" max-width="550"></v-img>
                <v-img v-if="edit_item.net_product_type_id == 14" :src="chat_alias_premium_picture_base+edit_item.filename" max-width="550"></v-img>
                <template v-if="edit_item.net_product_type_id == 13">
                  <video controls="controls" preload="auto" width="550" height="330" :poster="edit_item.thumbnail ? 'https://d2t3tbcav0uoyo.cloudfront.net/'+edit_item.thumbnail.filename : ''">
                      <source :src="'https://dgg8ygvhnykmd.cloudfront.net/'+edit_item.filename" type="video/mp4">
                  </video>
                </template>
              </template>

              <!-- if id is not set, show poista button -->
              <v-btn
                  v-if="!edit_item.id"
                  class="v-btn red"
                  @click="selectedFile = null;edit_item = {};selectedFileType = null"
              >
                Poista
              </v-btn>

              <!-- select net_product_type_id -->
              <v-select
                  v-if="!edit_item.id"
                  v-model="edit_item.net_product_type_id"
                  :items="productTypes.filter(f => f.type == selectedFileType)"
                  item-value="id"
                  item-text="name"
                  label="Tyyppi"
                  :disabled="productTypeSelectDisable"
              >
                <template v-slot:item="data">
                  {{ data.item.name }}
                </template>
              </v-select>

              <!-- video image -->
              <template v-if="selectedFileType == 'video'">

                <template v-if="!selectedFile2">
                  <v-file-input
                      v-model="selectedFile2"
                      accept="image/*"
                      label="Valitse videolle kansikuva"
                      truncate-length="50"
                  ></v-file-input>
                </template>

                <div v-if="media_upload" class="loading">

                  <v-progress-linear
                      v-if="axiosProgress > 0"
                      v-model="axiosProgress"
                      height="25"
                  >
                    <strong>{{ axiosProgress }}%</strong>
                  </v-progress-linear>
                  <!--<v-btn class="v-btn red" @click="abortLoading">Keskeytä</v-btn>-->
                </div>

                <img v-if="selectedFile2" :src="previewSrc2" style="max-width: 100%" :class="{'loading_media': media_upload}">

              </template>

              <!-- item price -->
              <v-text-field
                  v-if="edit_item.net_product_type_id == 13 || edit_item.net_product_type_id == 14"
                  v-model="edit_item.price"
                  placeholder="255"
                  label="Hinta (eurosenteissä)"
              ></v-text-field>

              <v-btn
                  v-if="!edit_item.id || edit_item.net_product_type_id != 18"
                  class="v-btn green"
                  @click="submitAliasMedia"
                  :disabled="loading">
                Tallenna
              </v-btn>

              <div v-if="edit_item.id">
                * Itse kuvaa tai videota ei voi muuttaa eikä sitä onko se profiilikuva tai maksullinen kuva. Poista olemassa oleva ja lisää uudestaan jos tarve muuttaa näitä asetuksia.
              </div>

            </template>

          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>

    <!-- popup of image -->
    <v-dialog
        v-model="popup"
        max-width="1000"
    >
      <v-card>
        <v-card-text>
          <img :src="popup_file" style="max-width: 100%"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- the snackbar, enjoy -->
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        color="green"
    >
      {{ snackbar_message }}
    </v-snackbar>

    <!-- confirmation -->
    <v-dialog
        v-model="confirmation"
        max-width="600"
    >
      <v-alert
          color="red darken-3"
      >
        Poistetaanko media {{ edit_item.id }}?

        <v-btn class="v-btn red ml-2" @click="deleteMedia(edit_item)">poista</v-btn>
      </v-alert>
    </v-dialog>

  </span>
</template>

<script>
//problems:
//1. when picture is uploaded, it will transfer it to a folder in s3 server. this will take some time and picture does not load to list correctly
//2. when video is uploaded, it will transfer it to a folder in s3 server. this will take time alot. and video does not appear to list properly
//3. picture filename is alias name. This will cause problems with cloudfront cache. temp picture should contain some timestamp and keep ts when storing profile picture and video poster
//3 (must keep filename for premium picture because of the order. store name too

import GlobalVars from "../mixins/GlobalVars";

export default {
  name: "AliasMedia",
  props: [
      'items',
      'id',
  ],
  mixins: [
    GlobalVars
  ],
  data() {
    return {
      groups: [
        { name: 'Profiilikuvat', net_product_type_id: 18},
        { name: 'Maksulliset kuvat', net_product_type_id: 14},
        { name: 'Maksulliset videot', net_product_type_id: 13},
      ],
      headers: [
        { value: 'filename', false: true},
        { text: 'tyyppi', value: 'net_product_type_id', sortable: false},
        { text: 'hinta', value: 'price', sortable: true},
        { value: 'edit', false: true},
      ],
      edit_item: {},
      dialog: false,
      confirmation: false,
      productTypes: [
        {id: 18, name: 'profiilikuva', type: 'image'},
        {id: 14, name: 'maksullinen kuva', type: 'image'},
        {id: 13, name: 'maksullinen video', type: 'video'},
      ],
      selectedFile: null,
      selectedFile2: null,
      popup: false,
      popup_file: false,
      media_upload: false, //is mediafile being uploaded
      axiosProgress: 0,
      selectedFileType: null,
      controller: null,
    }
  },
  computed: {

    //if type is video, then product type can not be changed
    productTypeSelectDisable(){

      if(this.selectedFileType == 'video')
        return true
      else
        return false

    },

    previewSrc() {
      if (!this.selectedFile) return;
      return URL.createObjectURL(this.selectedFile);
    },

    previewSrc2() {
      if (!this.selectedFile2) return;
      return URL.createObjectURL(this.selectedFile2);
    }

  },
  watch: {

    selectedFile(){

      if(!this.selectedFile) return

      //create formdata in order to upload selectedFile
      var formData = new FormData();

      formData.append('file', this.selectedFile);
      //formData.append('net_product_type_id', 13)
      //formData.append('price', 43434)

      this.controller = new AbortController()

      var aliasType = 'chat_aliases/'

      this.media_upload = true
      formData.append('signal', this.controller.signal)

      this.axios.post(aliasType+this.id+'/alias_media/', formData, {
        signal: this.controller.signal,
        //upload progress to track upload percentage
        onUploadProgress: progressEvent => this.axiosProgress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      })
          .then((response)=>{
          console.log(response)
          this.edit_item.filename = response.data.filename
          this.axiosProgress = 0
          this.media_upload = false

            if(this.selectedFileType == 'video')  this.edit_item.net_product_type_id = 13

        }).catch((e) => {
          console.log(e)
          this.loading = false
        });
    },

    selectedFile2(){

      if(!this.selectedFile2) return

      //create formdata in order to upload selectedFile
      var formData = new FormData();
      formData.append('file', this.selectedFile2);

      var aliasType = 'chat_aliases/'

      this.axios.post(aliasType+this.id+'/alias_media/', formData, {
        //upload progress to track upload percentage
        onUploadProgress: progressEvent => this.axiosProgress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      })
          .then((response)=>{
            console.log(response)
            this.edit_item.thumbnail = {
              filename: response.data.filename
            }
            this.axiosProgress = 0

          }).catch((e) => {
        console.log(e)
        this.loading = false
      });
    }
  },
  methods: {

    submitAdDomains(route, item){

      this.axios.post(route, item).then((response)=>{
        this.edit_item.filename = response.data.filename
        this.axiosProgress = 0
        this.media_upload = false

      }).catch((e) => {
        console.log(e)
      });

    },

    fileType(file){

      this.selectedFileType = file.type.split("/")[0]

    },

    deleteMedia(item){

      this.axios.delete('chat_aliases/'+this.id+'/alias_media/'+item.id).then((response)=>{
        console.log(response)

        this.$emit('remove_alias_media', item.id)
        this.confirmation = false

        this.snackbar = true
        this.snackbar_message = "media poistettu"

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },

    abortLoading(){


      console.log('abortLoading')


      this.controller.abort()

      // cancel the request
      this.selectedFile = null
      this.axiosProgress = 0
      this.media_upload = false
      this.edit_item.filename = null

    },

    sendingEvent (file, xhr, formData) {
      formData.append('net_product_type_id', this.edit_item.net_product_type_id)
      formData.append('price', this.edit_item.price)
    },

    submitAliasMedia(){

      var method = 'post'

      if(this.edit_item.id) method = 'put'

      var aliasType = 'chat_aliases/'

      this.axios[method](aliasType+this.id+'/alias_media/'+(this.edit_item.id ? this.edit_item.id : ''), this.edit_item).then((response)=>{
        console.log('submitAliasMedia', response.data)
        this.loading = false
        this.dialog = false
        this.selectedFile = null
        this.axiosProgress = 0

        if(method == 'post'){

          //wait until picture is moved to the server
          setTimeout(function () { this.$emit('alias_media', response.data)}.bind(this, response.data), 2000);


          if(this.edit_item.net_product_type_id == 18) this.$root.$emit('alias_profile_picture', [this.id, response.data])

          this.snackbar = true
          this.snackbar_message = "Lisätty uusi aliasmedia. Tallennetaan pilveen. Kuva tulee hetken päästä näkyville. Huom! Videon lataaminen voi kestää minuutin pari ennenkö sitä voi täällä toistaa."
        }else{
          this.snackbar = true
          this.snackbar_message = "Alias media päivitetty"
        }

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },

  }
}
</script>

<style scoped>
  .loading {
    position: absolute;
    height: 307px;
    width: 95%;
    padding-top:130px;
    z-index: 2;
  }
  .loading_media{
    opacity: 0.2;
  }
</style>