<template>
  <span>
    <v-row>
      <v-col
        v-for="img in items"
        :key="img.id"
        class="d-flex child-flex"
        cols="3"
        >
          <v-img
              :src="audio_alias_advertise_picture_base+img.filename"
              max-width="180"
              @click="dialog = true;edit_item=img;resetForm('form')"
              style="cursor: pointer"
          ></v-img>
      </v-col>

      <v-col
          class="d-flex child-flex grey text-center darken-3"
          cols="2"
          style="height: 120px;"
      >
        <div class="text-center;padding-top:60%">
          <v-btn
              class="v-btn ma-3 green"
              @click="dialog = true;edit_item={};resetForm('form')"
              small
          >
            <v-icon>mdi-plus-circle-outline</v-icon> Lisää kuva
          </v-btn>
        </div>

      </v-col>

    </v-row>

    <!-- dialog -->
    <v-dialog
        v-model="dialog"
        max-width="600"
    >
      <v-card
          color="grey darken-3"
      >
        <v-card-title v-if="edit_item.id">
          Muokkaa kuvaa
        </v-card-title>
        <v-card-title v-else>
          Uusi kuva
        </v-card-title>

        <v-card-text>

          <v-file-input
              v-if="!selectedFile && !edit_item.id"
              v-model="selectedFile"
              accept="image/*, video/mp4, video/webm"
              label="Kuva tai video (jpg,webp,mp4,webm)"
              truncate-length="50"
              @click:clear="edit_item.file = null"
              @change="fileType"
          ></v-file-input>

          <!-- image & video preview and load progress -->
          <div v-if="media_upload" class="loading">

            <v-progress-linear
                v-if="axiosProgress > 0"
                v-model="axiosProgress"
                height="25"
            >
              <strong>{{ axiosProgress }}%</strong>
            </v-progress-linear>
          </div>

          <img v-if="!edit_item.id && selectedFileType == 'image'" :src="previewSrc" style="max-width: 100%" :class="{'loading_media': media_upload}">

          <!-- show picture for existing picture -->
          <template v-if="edit_item.id">
            <v-img :src="audio_alias_advertise_picture_base+edit_item.filename" max-width="550"></v-img>
          </template>
          <!-- select net_product_type_id -->
          <v-select
              v-if="!edit_item.id && edit_item.filename"
              v-model="edit_item.net_product_type_id"
              :items="productTypes"
              item-value="id"
              item-text="name"
              label="Tyyppi"
          >
            <template v-slot:item="data">
              {{ data.item.name }}
            </template>
          </v-select>

          <!-- item price -->
          <v-text-field
              v-if="edit_item.net_product_type_id == 13 || edit_item.net_product_type_id == 14"
              v-model="edit_item.price"
              placeholder="255"
              label="Hinta (eurosenteissä)"
          ></v-text-field>

        </v-card-text>

          <!--
          <div style="font-size: x-small; color: mediumpurple">
            {{ edit_item }}
          </div>

          <div style="font-size: x-small; color: greenyellow">
            POST: /api/audio_aliases/{{ alias_id }}/alias_advertise/adv_id/alias_media  | Receive above json. upload picture to cloud(same way as for chat alias alias_media) creates entry in alias_media table and audio_alias_advertisables. respond with created entry as json<br/>
            PUT: /api/audio_aliases/{{ alias_id }}/alias_advertise/adv_id/alias_media/edit_item.id | update advertisement media(works same way as put in /api/chat_aliases/{id}/alias_media/{media_id}). Respond with the updated json object.<br/>
            DELETE: /api/audio_aliases/id/alias_advertise/{adv_id}/alias_media/edit_item.id  | delete picture.
          </div>
          -->

          <v-card-actions>
            <v-spacer/>
            <v-btn
                class="btn green"
                v-if="edit_item.id && (edit_item.net_product_type_id == 13 || edit_item.net_product_type_id == 1)"
                @click="submitModel('audio_aliases/'+alias_id+'/alias_advertise/'+advertise_id+'/alias_media', edit_item)"
            >
              Päivitä kuva
            </v-btn>

            <v-btn
                class="btn green"
                v-if="!edit_item.id"
                @click="submitModel('audio_aliases/'+alias_id+'/alias_advertise/'+advertise_id+'/alias_media', edit_item)"
            >
              Luo uusi kuva
            </v-btn>

            <v-btn
                v-if="edit_item.id"
                class="btn red"
                @click="confirmation = true"
            >
              poista
            </v-btn>
          </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- delete confirmation -->
    <v-dialog
        v-model="confirmation"
        max-width="600"
      >
      <v-alert
          color="red darken-3"
      >
        Poistetaanko kuva {{ edit_item.id }}?
        <v-btn class="v-btn red ml-2" @click="deleteModel('audio_aliases/'+alias_id+'/alias_advertise/'+advertise_id+'/alias_media/'+edit_item.id, edit_item)">poista</v-btn>
      </v-alert>
    </v-dialog>
  </span>
</template>

<script>
import GlobalVars from "../mixins/GlobalVars";

export default {
  name: "AliasMediaGallery",
  mixins: [
    GlobalVars,
  ], //import default set of values
  props: [
      'items',
      'alias_id',
      'advertise_id'
  ],
  watch: {

    selectedFile(){

      if(!this.selectedFile) return

      //create formdata in order to upload selectedFile
      var formData = new FormData();

      formData.append('file', this.selectedFile)

      this.media_upload = true

      this.axios.post('audio_aliases/'+this.alias_id+'/alias_advertise/'+this.advertise_id+'/alias_media', formData, {
        //upload progress to track upload percentage
        onUploadProgress: progressEvent => this.axiosProgress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      }).then((response)=>{
        this.edit_item.filename = response.data.filename
        this.axiosProgress = 0
        this.media_upload = false

      }).catch((e) => {
        console.log(e)
      });
      },
  },
  data() {
    return {
      dialog: false,
      edit_item: {},
      selectedFile: null,
      selectedFileType: null,
      confirmation: false,
      media_upload: false, //is mediafile being uploaded
      axiosProgress: 0,
      productTypes: [
        {id: 18, name: 'profiilikuva', type: 'image'},
        {id: 14, name: 'maksullinen kuva', type: 'image'},
        //{id: 13, name: 'maksullinen video', type: 'video'},
      ],
    }
  },
  computed: {

    previewSrc() {
      if (!this.selectedFile) return;
      return URL.createObjectURL(this.selectedFile);
    },

  },
  methods: {

    fileType(file){

      this.selectedFileType = file.type.split("/")[0]

    },
    submitModel(route, item){

      console.log(route)

      this.axios.post(route, item).then((r)=> {

        this.confirmation = false
        this.dialog = false

        setTimeout(function () {
          this.$emit('newPicture', r.data)
        }.bind(this), 1200);
      })

    },

    deleteModel(route, item){
      console.log(route)

      this.axios.delete(route).then(()=> {

        this.confirmation = false
        this.dialog = false
        this.$emit('deletePicture', item)})
    }




  }
}
</script>

<style scoped>
.loading {
  position: absolute;
  height: 307px;
  width: 95%;
  padding-top:130px;
  z-index: 2;
}
.loading_media{
  opacity: 0.2;
}
</style>