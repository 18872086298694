<template>
  <span>
    <v-row>
      <v-col>
        <v-btn
            class="v-btn ma-3 green float-right"
            @click="edit_item = {alias_id: id}; dialog=true;resetForm('edit_item')"
            small
        >
          <v-icon>mdi-plus-circle-outline</v-icon> Lisää esittely
        </v-btn>
      </v-col>
    </v-row>

    <!-- ads table -->
    <v-data-table
          :items="items"
          :headers="headers"
          :hide-default-footer="true"
          class="mb-4"
          :items-per-page=-1
          @click:row="edit_item = $event;dialog=true"
      >


      <template slot="item.alias_advertise_has_domains" slot-scope="data">
        <template v-if="data.item.alias_advertise_has_domains && data.item.alias_advertise_has_domains[0]">
          {{ data.item.alias_advertise_has_domains[0].domain.name }}

          <v-icon
              v-if="!data.item.active"
              color="red"
              title="ei aktiivisena"
              class="ml-2"
          >
            mdi-account-cancel
          </v-icon>
        </template>
      </template>

        <template slot="item.number" slot-scope="data">
          <span v-if="data.item.alias_advertise_has_domains && data.item.alias_advertise_has_domains[0] && data.item.alias_advertise_has_domains[0].operator_number">
            {{ data.item.alias_advertise_has_domains[0].operator_number.number }}
          </span>
          <span v-if="data.item.alias_advertise_has_domains && data.item.alias_advertise_has_domains[0] && data.item.alias_advertise_has_domains[0].credio_number">
            {{ data.item.alias_advertise_has_domains[0].credio_number.number }}
          </span>
        </template>

        <template slot="item.picture_data" slot-scope="data" @click="dialog = true">
            <v-img
                v-if="data.item.alias_media && data.item.alias_media[0]"
                :src="audio_alias_advertise_picture_base+data.item.alias_media[0].filename"
                max-width="100"
            ></v-img>
        </template>

      </v-data-table>

      <!-- ad dialog -->
      <v-dialog
          v-model="dialog"
          max-width="1200"
      >
        <v-card>
            <v-card-title v-if="edit_item.id">
              Muokkaa esittelyä
            </v-card-title>
            <v-card-title v-else>
              Uusi esittely
            </v-card-title>

          <v-card-text>

            <!-- picture gallery. Show pictures in two rows, open modal on click
              in the end, show column with button to add new picture, open modal when clicked
              modal show source picture source. Show who uploaded, show edit button
              add to alias_media on newPicture
            -->
            <h2 v-if="edit_item.alias_media && edit_item.alias_media.length == 1">Kuva</h2>
            <h2 v-if="edit_item.alias_media && edit_item.alias_media.length > 1">Kuvia</h2>

            <alias-media-gallery
                v-if="edit_item && edit_item.id"
                :items="edit_item.alias_media"
                :advertise_id="edit_item.id"
                :alias_id="id"
                @newPicture="newPicture($event)"
                @deletePicture="deletePicture($event)"
            ></alias-media-gallery>

            <!-- formFields loop -->
            <v-form
                class="mt-4 form-element"
                ref="edit_item"
            >

            <!-- form fields loop-->
            <span v-for="data in formFields" :key='data.item_id'>

              <!-- show chips when needed -->
              <v-chip-group
                v-if="data.chips"
                column
              >
                <!-- single chip -->
                <v-chip
                    color="blue"
                    label
                    class="darken-4"
                    v-if="!data.multiple && edit_item[data.item_id]"
                    :close="data.chips_close"
                    @click:close="chipsClose(data, edit_item[data.item_id])"
                    :large="data.chips_large"
                >
                  {{ edit_item[data.item_id].description }}
                </v-chip>

                <!-- multiple chips -->
                <v-chip v-else
                    color="blue"
                    label
                    class="darken-4"
                    v-for="chip in item[data.item_id]"
                    :key="'chip'+chip.id"
                    :close="data.chips_close"
                    @click:close="chipsClose(data, chip)"
                >
                  {{ data.chips_name ? chip[data.chips_name] : chip.name }}
                </v-chip>
              </v-chip-group>

              <!-- anonymous component to handle form stuff -->
              <component
                  :is="data.component"
                  v-model="edit_item[data.item_id]"
                  :items="searchItems[data.item_id]"
                  :label="data.label"
                  :hint="data.hint"
                  :persistent-hint="data.persistent_hint"
                  :search-input.sync="searchInputs[data.item_id]"
                  :loading="searchLoading[data.item_id]"
                  :item-text="data.item_text"
                  :item-value="data.item_value"
                  :no-data-text="data.no_data_text"
                  :multiple="data.multiple"
                  :chips="data.chips"
                  :return-object="data.return_object"
                  :background-color="data.background_color"
                  :rules="data.rules"
                  hide-no-data
              >

                <!-- disable selection, using external selection system -->
                <template v-slot:selection></template>

                <template v-slot:item="data">
                  <template v-if="data.item.name">{{ data.item.name }}</template>
                  <template v-if="data.item.info">{{ data.item.info }}</template>
                  <template v-if="data.item.description">{{ data.item.description }}</template>
                </template>
                <template v-if="data.v_html">
                  {{ data.v_html }}
                </template>
                <template v-if="data.radios">
                    <v-radio v-for="r in data.radios"
                             :key="r.key"
                             :value="r.value"
                             :label="r.label"
                    ></v-radio>
                </template>
              </component>

            </span>


            <!-- has domains segment -->
            <v-row>
              <v-col>
                <h2 v-if="edit_item.id">Esittely liitetty domaineihin:</h2>
                <h2 v-else>(Voit liittää domainin ja lisätä kuvan vasta kun esittely on luotu)</h2>
              </v-col>
              <v-col cols="2">
                <v-btn
                    class="v-btn ma-3 green float-right"
                    @click="has_domain_dialog=true; edit_has_domain={}"
                    small
                    :disabled="!edit_item.id"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon> Liitä domain
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table
                      :items="edit_item.alias_advertise_has_domains"
                      :headers="domain_headers"
                      :hide-default-footer="true"
                      class="mb-4"
                      :items-per-page=-1
                      @click:row="edit_has_domain= $event;has_domain_dialog=true"
                  >
              <template slot="item.domain" slot-scope="data">
                {{ data.item.domain.name }}
              </template>
              <template slot="item.operator_number" slot-scope="data">
                <template v-if="data.item.operator_number">
                  {{ data.item.operator_number.number }}
                </template>
              </template>
              <template slot="item.credio_number" slot-scope="data">
                <template v-if="data.item.credio_number">
                  {{ data.item.credio_number.number }}
                </template>
              </template>
            </v-data-table>

      </v-form>

          </v-card-text>

          <!--
          <div style="font-size: x-small; color: mediumpurple">
            {{ edit_item }}
          </div>

          <div style="font-size: x-small; color: greenyellow">
            POST: /audio_aliases/1598/alias_advertise/802/ad_domains/ads | Receive above json. Create a new has_domain entry to db. Respond with the created json object.<br/>
            PUT: /audio_alias/{{ id }}/ads/edit_itemid | Receive above json. Update has_domain entry by id. Respond with the updated json object.<br/>
            DELETE: /audio_alias/{{ id }}/edit_item.id  | Delete object.
          </div>

          -->

          <v-card-actions>
            <v-spacer/>
            <v-btn
                class="btn green"
                @click="submitAdvertise('/audio_aliases/'+id+'/alias_advertise', edit_item)"
            >
              <template v-if="edit_item.id">
                Päivitä esittely
              </template>
              <template v-else>
                Luo uusi esittely
              </template>
            </v-btn>
            <v-btn
                v-if="edit_item.id"
                class="btn red"
                @click="confirmation_delete_advertise = true"
            >
              poista esittely
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- has_domain dialog -->
      <v-dialog
          v-model="has_domain_dialog"
          max-width="500px"
      >
        <v-card
          color="grey darken-3"
        >
            <v-card-title v-if="edit_has_domain.id">
              Muokkaa esittelyn domain linkitystä
            </v-card-title>
            <v-card-title v-else>
              Uusi esittelyn domain linkitys
            </v-card-title>

          <v-card-text>

            <!-- formFieldsHasDomain loop -->
            <span v-for="data in formFieldsHasDomain" :key='data.item_id'>

              <!-- show chips when needed -->
              <v-chip-group
                  v-if="data.chips"
                  column
              >
                <!-- single chip -->
                <v-chip
                    color="blue"
                    label
                    class="darken-4"
                    v-if="!data.multiple && edit_has_domain[data.item_id]"
                    :close="data.chips_close"
                    @click:close="chipsClose(data, edit_has_domain[data.item_id])"
                    :large="data.chips_large"
                >
                  <!--
                  <span class="link-like" @click="dialogs[data.chips_dialog] ? dialogs[data.chips_dialog] = false : dialogs[data.chips_dialog] = true">
                    <template v-if="edit_has_domain[data.item_id].name">{{ edit_has_domain[data.item_id].name }}</template>
                    <template v-if="edit_has_domain[data.item_id].number">{{ edit_has_domain[data.item_id].number }}</template>
                  </span>
                  -->
                    <template v-if="edit_has_domain[data.item_id].name">{{ edit_has_domain[data.item_id].name }}</template>
                    <template v-if="edit_has_domain[data.item_id].number">{{ edit_has_domain[data.item_id].number }}</template>
                </v-chip>

              </v-chip-group>

              <!-- anonymous component to handle form stuff -->
              <component
                  :is="data.component"
                  v-model="edit_has_domain[data.item_id]"
                  :items="searchItems[data.item_id]"
                  :label="data.label"
                  :hint="data.hint"
                  :persistent-hint="data.persistent_hint"
                  :search-input.sync="searchInputs[data.item_id]"
                  :loading="searchLoading[data.item_id]"
                  :item-text="data.item_text"
                  :item-value="data.item_value"
                  :no-data-text="data.no_data_text"
                  :multiple="data.multiple"
                  :chips="data.chips"
                  :return-object="data.return_object"
                  :background-color="data.background_color"
                  :rules="data.rules"
                  hide-no-data
              >

                <!-- disable selection, using external selection system -->
                <template v-slot:selection></template>

                <template v-slot:item="data">
                  <template v-if="data.item.name">{{ data.item.name }}</template>
                  <template v-if="data.item.number">{{ data.item.number }}</template>
                </template>
                <template v-if="data.v_html">
                  {{ data.v_html }}
                </template>
              </component>

            </span>

          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              class="btn green"
              @click="submitAdDomains('audio_aliases/'+id+'/alias_advertise/'+edit_item.id+'/ad_domains', edit_has_domain)"
            >
              tallenna
            </v-btn>
            <v-btn
                v-if="edit_has_domain.id"
                class="btn red"
                @click="confirmation_delete_has_domain = true"
            >
              poista
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <!-- domain dialog -->
    <v-dialog
      v-model="dialogs.domain"
      max-width="700"
    >
      <v-card
          color="grey darken-2"
      >
        <v-card-title v-if="edit_domain.id">
          Muokkaa domainia
        </v-card-title>
        <v-card-title v-else>
          Uusi domain
        </v-card-title>

        <v-card-text>

          <!-- formFieldsDomain-->
          <span v-for="data in formFieldsDomain" :key='data.item_id'>

            <!-- anonymous component to handle form stuff -->
            <component
                :is="data.component"
                v-model="edit_domain[data.item_id]"
                :items="searchItems[data.item_id]"
                :label="data.label"
                :hint="data.hint"
                :persistent-hint="data.persistent_hint"
                :search-input.sync="searchInputs[data.item_id]"
                :loading="searchLoading[data.item_id]"
                :item-text="data.item_text"
                :item-value="data.item_value"
                :no-data-text="data.no_data_text"
                :multiple="data.multiple"
                :chips="data.chips"
                :return-object="data.return_object"
                :background-color="data.background_color"
                :rules="data.rules"
                hide-no-data
            >

              <!-- disable selection, using external selection system -->
              <template v-slot:selection></template>

            </component>

          </span>

        </v-card-text>

        <v-card-actions>
          <v-btn
              class="btn"
              @click="submit('has_domain', edit_item)"
          >
            tallenna
          </v-btn>
          <v-spacer/>
          <v-btn
              class="btn v-alert"
              @click="deleteModel('has_domain', edit_item)"
          >
            poista
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- number dialog -->
    <v-dialog
        v-model="dialogs.number"
        max-width="700px"
    >
      <v-card
          color="grey darken-2"
      >
        <v-card-title v-if="edit_number.id">
          Muokkaa numeroa
        </v-card-title>
        <v-card-title v-else>
          Uusi numero
        </v-card-title>

        <v-card-text>
          nro operator yleensä 18 (elisa) ja 21 (sip), 30 (setera), tariff 41, 94, 123, 89

          <!-- formFieldsNumber-->
          <span v-for="data in formFieldsNumber" :key='data.item_id'>

            <!-- anonymous component to handle form stuff -->
            <component
                :is="data.component"
                v-model="edit_number[data.item_id]"
                :items="searchItems[data.item_id]"
                :label="data.label"
                :hint="data.hint"
                :persistent-hint="data.persistent_hint"
                :search-input.sync="searchInputs[data.item_id]"
                :loading="searchLoading[data.item_id]"
                :item-text="data.item_text"
                :item-value="data.item_value"
                :no-data-text="data.no_data_text"
                :multiple="data.multiple"
                :chips="data.chips"
                :return-object="data.return_object"
                :background-color="data.background_color"
                :rules="data.rules"
                hide-no-data
            >

              <!-- disable selection, using external selection system -->
              <template v-slot:selection></template>

            </component>

          </span>

        </v-card-text>

        <v-card-actions>
            <v-spacer/>
            <v-btn
                class="btn green"
                @click="submit('alias_advertise', edit_item)"
            >
              tallenna
            </v-btn>
            <v-btn
                class="btn red"
                @click="deleteModel('alias_advertise', edit_item)"
            >
              poista
            </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- delete confirmation -->
    <v-dialog
        v-model="confirmation_delete_has_domain"
        max-width="600"
    >
      <v-alert
          color="red darken-3"
      >
        Poistetaanko domain linkitys {{ edit_has_domain.id }}?
        <v-btn class="v-btn red ml-2" @click="deleteHasDomain('audio_aliases/'+id+'/alias_advertise/'+edit_item.id+'/ad_domains/'+edit_has_domain.id, edit_has_domain.id)">poista</v-btn>
      </v-alert>
    </v-dialog>

    <!-- delete confirmation 2 -->
    <v-dialog
        v-model="confirmation_delete_advertise"
        max-width="600"
    >
      <v-alert
          color="red darken-3"
      >
        Poistetaanko esittely {{ edit_item.id }}?
        <v-btn class="v-btn red ml-2" @click="deleteAdvertise('/audio_aliases/'+id+'/alias_advertise/'+edit_item.id, edit_item.id)">poista</v-btn>
      </v-alert>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        color="green"
    >
      {{ snackbar_message }}
    </v-snackbar>

  </span>
</template>

<script>

import GlobalVars from "../mixins/GlobalVars";
import AliasMediaGallery from "./AliasMediaGallery";

//manually import vuetify form objects for dynamic usage
import {
  VTextField,
  VTextarea,
  VAutocomplete,
  VDivider,
  VCheckbox,
  VSelect
} from 'vuetify/lib'

export default {
  name: "AliasAdvertise",
  mixins: [
    GlobalVars
  ],
  props: [
    'id',
  ],
  components: {
    VTextField,
    VTextarea,
    VDivider,
    VCheckbox,
    VAutocomplete,
    VSelect,
    AliasMediaGallery
  },
  data() {
    return {
      edit_item: {},
      edit_has_domain: {},
      edit_domain: {},
      edit_number: {},
      edit_picture: {},
      items: [],
      dialogs: {
        number: false,
        domain: false,
      },
      confirmation_delete_has_domain: false,
      confirmation_delete_advertise: false,
      formFields: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'introduction',
          label: 'Esittelyteksti',
          hint: null,
          component: 'v-textarea',
          rules: [(v) => !!v || "Esittelyteksti olla tyhjä"],
        },
        {
          item_id: 'active',
          label: 'Aktiivinen',
          hint: null,
          component: 'v-checkbox',
        },
      ],
      formFieldsHasDomain: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'domain',
          label: 'Domain',
          hint: null,
          class: "mb-6",
          component: 'v-autocomplete',
          multiple: false,
          chips: true,
          chips_close: true,
          chips_close_action: true,
          //chips_dialog: 'domain',
          chips_close_message: "Domain poistettiin esittelyn domain linkistä",
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
        },
        {
          item_id: 'operator_number',
          label: 'Operaattorinumero',
          hint: null,
          class: "mb-6",
          component: 'v-autocomplete',
          multiple: false,
          chips: true,
          chips_close: true,
          chips_close_action: true,
          //chips_dialog: 'number',
          chips_close_message: "Operaattorinumero poistettiin esittelyn domain linkistä",
          return_object: true,
          item_text: "number",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
        },
        {
          item_id: 'credio_number',
          label: 'Credionumero',
          hint: null,
          class: "mb-6",
          component: 'v-autocomplete',
          multiple: false,
          chips: true,
          chips_close: true,
          chips_close_action: true,
          chips_close_message: "Credionumero poistettiin esittelyn domain linkistä",
          return_object: true,
          item_text: "number",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
        },
        {
          item_id: 'SMSKey',
          label: 'SMS-avainsana',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'SMSShortCode',
          label: 'SMS-numero',
          hint: null,
          component: 'v-text-field',
        },
      ],
      formFieldsNumber: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'active',
          label: 'Aktiivinen numero',
          hint: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'number',
          label: 'Numero',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'technicalNumber',
          label: 'Tekninen numero',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'operator_id',
          label: 'Operaattori ID',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'tariff_id',
          label: 'Tariff ID',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'group_id',
          label: 'Ryhmä ID',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'number_admin_id',
          label: 'Numeron admin ID',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'start_date',
          label: 'Alkamispäivä',
          hint: null,
          component: 'v-text-field',
        },
      ],
      formFieldsDomain: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'name',
          label: 'Domain',
          hint: null,
          component: 'v-text-field',
        },
        {
          item_id: 'project_id',
          label: 'Projektin ID',
          hint: null,
          component: 'v-text-field',
        },
      ],
      dialog: false,
      has_domain_dialog: false,
      domain_dialog: false,
      number_dialog: false,
      headers: [
        { value: 'picture_data'},
        { text: 'kuvaus', value: 'introduction', sortable: false},
        { text: 'domain', value: 'alias_advertise_has_domains', sortable: true},
        { text: 'numero(t)', value: 'number'},
      ],
      domain_headers: [
        { text: 'Domain', value: 'domain'},
        { text: 'Operaattori nro.', value: 'operator_number', sortable: false},
        { text: 'Credio nro', value: 'credio_number', sortable: false},
        { text: 'SMS-avainsana', value: 'SMSKey', sortable: false},
        { text: 'SMS-nro.', value: 'SMSShortCode', sortable: false},
      ],
      searchItems: {
        domain: null,
        operator_number: null,
        credio_number: null,
        alias_ad_type: [
          {id: 2, description: 'Web'},
          {id: 3, description: 'WebCall'},
          {id: 4, description: 'TJ'},
        ]
      },
      searchInputs: {
        domain: "",
        operator_number: "",
        credio_number: "",
      },
      searchLoading: {
        domain: false,
        operator_number: false,
        credio_number: false,
      },
    }
  },
  watch: {
    id(){
      this.getItems()
    },
    "searchInputs.operator_number"() {

      if (this.searchInputs.operator_number) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('operator_number', 'numbers', this.searchInputs.operator_number)
        }, 500)
      }
    },
    "searchInputs.credio_number"() {

      if (this.searchInputs.credio_number) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('credio_number', 'numbers', this.searchInputs.credio_number)
        }, 500)
      }
    },
    "searchInputs.domain"() {

      if (this.searchInputs.domain) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('domain', 'domains', this.searchInputs.domain)
        }, 500)
      }
    },



  },
  methods: {

    newPicture(picture){

      if(!this.edit_item.alias_media) this.edit_item.alias_media = []

      this.edit_item.alias_media.push(picture)

      const index = this.items.findIndex(obj => obj.id === this.edit_item.id)
      console.log('index: '+index)
      this.items[index] = this.edit_item

      this.snackbar=true
      this.snackbar_message='kuva lisätty!'

    },

    chipsClose(chip){

      this.edit_has_domain[chip.item_id] = null

      this.snackbar_message = chip.chips_close_message
      this.snackbar = true
    },

    deleteAdvertise(route, id){


      this.axios.delete(route, id).then(()=> {

        this.confirmation_delete_advertise = false
        this.dialog = false

        //delete from alias_advertise_has_domains
        this.items.splice(this.items.findIndex(x => x.id == id), 1)

        this.snackbar = true
        this.snackbar_message = 'esittely poistettu'

      })
    },

    deleteHasDomain(route, id){

      this.axios.delete(route).then(()=> {

        this.confirmation_delete_has_domain = false
        this.has_domain_dialog = false

        //delete from alias_advertise_has_domains
        this.edit_item.alias_advertise_has_domains.splice(this.edit_item.alias_advertise_has_domains.findIndex(x => x.id == id), 1)


      })

    },

    deletePicture(picture){

      console.log('deletePicture', picture);

      this.edit_item.alias_media.splice(this.edit_item.alias_media.findIndex(x => x.id == picture.id), 1)

      this.snackbar = true
      this.snackbar_message = "kuva on poistettu"
    },

    submitAdvertise(route, item){

      let method = 'post'
      if(item.id){
        method = 'put'
        route = route+'/'+item.id
      }

      this.axios[method](route,item).then((response)=>{
        console.log(response)
        this.loading = false

        //push to items
        if(method == 'post'){

          this.items.push(response.data)
          this.edit_item = response.data
        }

        //replace an item
        if(method == 'put') {
          const index = this.items.findIndex(obj => obj.id === response.data.id)
          this.items[index] = response.data
          this.dialog = false
        }

        //close window
        //this.dialog = false

        //give message
        this.snackbar = true
        this.snackbar_message = 'Esittely lisätty / päivitetty'

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },

    submitAdDomains(route, item){

      let method = 'post'
      if(item.id){
        method = 'put'
        route = route+'/'+item.id
      }

      this.axios[method](route,item).then((response)=>{
        console.log(response)
        this.loading = false

        //push to alias_advertise_has_domains
        if(method == 'post'){
          if(!this.edit_item.alias_advertise_has_domains) this.edit_item.alias_advertise_has_domains = []
          this.edit_item.alias_advertise_has_domains.push(response.data)
        }

        //replace an item
        if(method == 'put') {
          const index = this.edit_item.alias_advertise_has_domains.findIndex(obj => obj.id === response.data.id)
          this.edit_item.alias_advertise_has_domains[index] = response.data
        }

        const index = this.items.findIndex(obj => obj.id === this.edit_item.id)
        console.log('index: '+index)
        this.items[index] = this.edit_item

        //close domain window
        this.has_domain_dialog = false

        //give message
        this.snackbar = true
        this.snackbar_message = 'Domain lisätty / päivitetty'

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },
    
    submit(route, item){

      let method = 'post'

      if(item.id) method = 'put'

      this.axios[method](route,item).then((response)=>{
        console.log(response)
        this.loading = false

        //push to parent
        //close window
        //give message

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },

    getItems(){

      if(!this.id) return //silently fail if id is not yeat available

      this.axios.get('audio_aliases/'+this.id+'/alias_advertise').then( results => {
        this.items = results.data
      })

    },

    binaryPicture(bin) {
      return URL.createObjectURL(bin);
    },

  },
  mounted(){
    this.getItems()

  }

}
</script>

<style scoped>
.link-like {
  font-weight: bold;
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
}
</style>